import React from "react";

import { Button } from "antd";

const DemoInfo = () => {
  return (
    <div>
      <h2>Request Demo</h2>
      <p>
        We can set you up on our demo account so you can test it out without any
        commitment in your business, simply Contact Us by email and let us know
        a little bit about your requirements and we will get back to you with
        instructions.
      </p>
      <div style={{ textAlign: "right" }}>
        <Button type="primary">
          <a href="mailto:sales@dayviewer.com?subject=Request%20for%20DayViewer%20Team%20Room%20Demo">
            Request Demo
          </a>
        </Button>
      </div>
    </div>
  );
};

export default DemoInfo;
